// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

// ヘッダーメニュー
$(function() {
  $('.header-menu-btn').on('click', function() {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $('.header-nav').removeClass('show');
    } else {
      $(this).addClass('active');
      $('.header-nav').addClass('show');

      // GSAP（時間差フェードイン）
      let clickEffect = document.querySelectorAll('.step-click-anime');
      clickEffect.forEach((target)=>{
        let targetChild = target.querySelectorAll('.step-click-anime-item');
        gsap.fromTo( // fromTo アニメーション開始時と終了時の状態を指定
          targetChild,
          { // アニメーション開始時の状態
            y: 70,
            autoAlpha: 0,
          },
          { // アニメーション終了時の状態
            y: 0,
            duration: 1.5,
            delay: 0.4,
            autoAlpha: 1,
            ease: "elastic.out(1,.75)",
            stagger:{
              each: 0.05,
            },
          })
      });
    }
  });
});

// ヘッダーナビのアニメーション
function slideInTextHeader() {
  const element = $('.test-anime-header');
  const delayTime = 100;
  element.each(function (i) {
    $(this).delay(i * delayTime).queue(function () {
      $(this).addClass('slideAnimeDownUp').dequeue();
    });
  });
}

// KVのアニメーション
// ページ読み込み時
$(window).on('load', function(){
  slideInText();
  slideInKv();
});
// ACCRタイトル
function slideInText() {
  const element = $('.slide-in-text-inner');
  const delayTime = 200;
  element.each(function (i) {
    $(this).delay(i * delayTime).queue(function () {
      $(this).addClass('slideAnimeDownUp').dequeue();
    });
  });
}
// KV画像
function slideInKv() {
  $('.kv-main').delay(1300).queue(function(){
    $(this).addClass('active');
  });
}

// GSAP（スクロールアニメーション（フェードイン））
let fadeupEffect = gsap.utils.toArray(".fadeup-anime");
fadeupEffect.forEach((target) => {
  gsap.fromTo(  // fromTo アニメーション開始時と終了時の状態を指定
    target, 
    { // アニメーション開始時の状態
      y: 70, 
      autoAlpha: 0,
    },
    { // アニメーション終了時の状態
      y: 0,
      autoAlpha: 1,
      duration: 1.6,
      ease: "elastic.out(1,.75)",
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      },
    }
  );
});

// GSAP（スクロールアニメーション（時間差フェードイン））
let stepEffect = document.querySelectorAll('.step-anime');
stepEffect.forEach((target)=>{
  let targetChild = target.querySelectorAll('.step-anime-item');
  gsap.fromTo( // fromTo アニメーション開始時と終了時の状態を指定
    targetChild,
    { // アニメーション開始時の状態
      y: 70,
      autoAlpha: 0,
    },
    { // アニメーション終了時の状態
      y: 0,
      duration: 1.5,
      autoAlpha: 1,
      ease: "elastic.out(1,.75)",
      stagger:{
        each: 0.1
      },
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      }    
    })
});
